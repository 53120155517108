import revive_payload_client_vNIVTHkKuR from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5__2hownp5c3pbwqidaqfj3ho5xme/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_6x6MfIQfnd from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5__2hownp5c3pbwqidaqfj3ho5xme/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8pyxlrxuHg from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5__2hownp5c3pbwqidaqfj3ho5xme/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_g6Jk3efyBy from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5__2hownp5c3pbwqidaqfj3ho5xme/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_D7GE5cSBfy from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5__2hownp5c3pbwqidaqfj3ho5xme/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_uZQnyQVpPJ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5__2hownp5c3pbwqidaqfj3ho5xme/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SilJYGde8B from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5__2hownp5c3pbwqidaqfj3ho5xme/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_mhaEOiypWL from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5__2hownp5c3pbwqidaqfj3ho5xme/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  revive_payload_client_vNIVTHkKuR,
  unhead_6x6MfIQfnd,
  router_8pyxlrxuHg,
  payload_client_g6Jk3efyBy,
  navigation_repaint_client_D7GE5cSBfy,
  check_outdated_build_client_uZQnyQVpPJ,
  chunk_reload_client_SilJYGde8B,
  components_plugin_KR1HBZs4kY,
  prefetch_client_mhaEOiypWL
]